<template>
  <div class="layout-padding">
    <div class="layout-padding-auto layout-padding-view">
      <el-table
          :data="data.tableData"
          style="width: 100%"
      >
        <el-table-column prop="landInfo.title" label="标题" show-overflow-tooltip >
          <template #default="scope">
            <span style="cursor: pointer;" @click="toDetail(scope.row.landInfo)" >{{scope.row.landInfo.title}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="landInfo.regionId" label="所属行政区" show-overflow-tooltip>
          <template #default="scope">
            {{ findLabel(land_area, scope.row.landInfo.regionId) }}
          </template>
        </el-table-column>
        <el-table-column prop="landInfo.ownerOrg" label="土地权属单位" width="120" show-overflow-tooltip />

        <el-table-column prop="amount" label="交易总金额" show-overflow-tooltip>
          <template #default="scope"> {{ scope.row.amount || 0 }} 万元 </template>
        </el-table-column>
        <el-table-column prop="landInfo.contact" label="转让方">
          <template #default="scope">
            <UserTag :userId="scope.row.sellerId" />
          </template>
        </el-table-column>
        <el-table-column prop="landInfo.contact" label="受让方">
          <template #default="scope">
            <UserTag :userId="scope.row.buyerId" />
          </template>
        </el-table-column>
        <el-table-column prop="transactionStatus" label="交易状态" show-overflow-tooltip>
          <template #default="scope">
            {{ findLabel(agreement_transaction_status, scope.row.transactionStatus) }}
          </template>
        </el-table-column>

        <el-table-column prop="updateTime" label="操作时间" show-overflow-tooltip />
        <el-table-column label="操作" width="180">
          <template #default="scope">
            <el-button icon="edit-pen" v-if="!isFinish(scope.row)  && scope.row.sellerId== user.userinfoID" text  style="background: rgb(43, 112, 194);border: 1px solid lightskyblue;font-size: 14px;padding: 3px 4px;color: white"  @click="onSendApply(scope.row)">转让方</el-button>
            <el-button icon="edit-pen" v-if="!isFinish(scope.row)  && scope.row.buyerId== user.userinfoID" text  style="background: rgb(43, 112, 194);border: 1px solid lightskyblue;font-size: 14px;padding: 3px 4px;color: white"  @click="onSendApplynew(scope.row)">受让方</el-button>

          </template>
        </el-table-column>
      </el-table>
      <div class="user-info-list-page">
        <el-pagination background layout="prev, pager, next" :page-count="data.totalPage" :current-page="data.currentPage" @current-change="pageChange">
        </el-pagination>
      </div>
      <agreement-trade-files-dialog ref="agreementTradeFilesDialogRef" @refresh="pageChange" />
    </div>


  </div>
</template>

<script setup  name="agreementTrade">
import {reactive, ref, defineAsyncComponent, onMounted} from 'vue'
import {Message, MessageBox} from 'element-ui'
import { useDict, findLabel} from '@/hooks/dict';
import UserTag from '@/components/UserTag.vue';
import CommonService from "@/service/CommonService";
import {useRouter} from "vue-router/composables";

const router = useRouter();
const AgreementTradeFilesDialog = defineAsyncComponent(() => import('@/components/agreement_trade_files.vue'));

const agreementTradeFilesDialogRef = ref();

const data = reactive({
  pageSize:10,
  currentPage:1,
  totalPage:1,
  tableData: [
  ]
})

const user = reactive({
  userinfoID:"",
})


// 引入组件
// const LandSelectorDialog = defineAsyncComponent(() => import('./../land_info/land_selector.vue'));
// const FormDialog = defineAsyncComponent(() => import('./form.vue'));
// const FileDialog = defineAsyncComponent(() => import('./../land_info/files.vue'));
// 定义查询字典

const { agreement_transaction_status, transaction_way, land_area, land_purpose, land_owner_type, transaction_person_type } = useDict(
    'agreement_transaction_status',
    'transaction_way',
    'land_area',
    'land_purpose',
    'land_owner_type',
    'transaction_person_type'
);

const isFinish = (row) => {
  return row.transactionStatus === 'finish'
}
const formDialogRef = ref();

const onSendApply = async (params) => {

  agreementTradeFilesDialogRef.value.openDialog(params, 'protocol');
};



const onSendApplynew= async (params) => {
  agreementTradeFilesDialogRef.value.openDialog(params, 'protocolnew');
};
const defaultLandQueryParams = ref({
  auditStatus: 2, //审核状态=审核通过
  current: 1, //审核状态=审核通过
  my: 1,
});

const pageChange = (page) =>{
  loadData(page);
}

onMounted(() => {
  loadData(1);
  user.userinfoID= localStorage.getItem('user_id');
})
const loadData = (page) =>{
  defaultLandQueryParams.value.current = page
  CommonService.getAgreementTransactionList(defaultLandQueryParams).then((res)=>{

    if(res.code === 0){

      for (let i=0;i<res.data.records.length;i++) {
        if (res.data.records[i].landInfo != undefined) {
          console.log(res.data.records[i].landInfo.title)
          res.data.records[i].landInfo.title = res.data.records[i].landInfo.title.replace("出租出租", '出租')
          res.data.records[i].landInfo.title = res.data.records[i].landInfo.title.replace("转让转让", '转让')
        }
      }
      data.tableData = res.data.records;
    }
  })
}

const   toDetail=(landInfo)=>{
  if(landInfo.auctionInfo &&  landInfo.transactionWay === "listing"){
    router.push("/trade/gp/"+this.landInfo.auctionInfo.id);
  }
  else if( landInfo.transactionWay === "auction" && landInfo.auctionInfo){
    router.push("/land/auction/"+landInfo.id);
  }
  else{
    router.push("/land/detail/"+landInfo.id);
  }
}



</script>
